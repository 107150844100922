import Inputmask from "inputmask";
import HtmlUtility from '../utility/HtmlUtility';
import CryptoJS from "crypto-js";
import LoanAppValidator from "../validation/LoanApplicationValidator";

if (
  window.location.pathname === "/loan-application" ||
  window.location.pathname === "/loan-application/"
) {

  let dateFields = document.getElementsByClassName("dob");
  for (let i = 0; i < dateFields.length; i++) {
    Inputmask("99/99/9999").mask(dateFields[i]);
  }

  // Debug: disable form validation
  let debug = false;

  $(function () {
    $('.nextBtn').prop('disabled', false);
    populateFormAndSetStep();
    buildValidator();


    $("#continueSession").on('click', function () {
      refreshToken();
    });

    $("#continueActivity").on('click', function () {
      continueActivity();
    });

    checkIdle();
    //sessionExpiring(90);
  });

  // Apply Input Masks
  let phoneFields = document.getElementsByClassName("phone");
  for (let i = 0; i < phoneFields.length; i++) {
    Inputmask("(999) 999-9999").mask(phoneFields[i]);
  }

  //Inputmask("99/99/9999").mask(document.getElementById("date_of_birth"));
  Inputmask("99/99/9999").mask(document.getElementById("first_pay_day"));

  // Init DateTime Pickrs using "flatpickr"
  flatpickr("#first_pay_day", {
    disableMobile: "true",
    dateFormat: "m/d/Y",
    allowInput: true,
    enable: [
      function (date) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        // only allow future dates and weekdays ( no saturday or sunday )
        return date > today && date.getDay() !== 0 && date.getDay() !== 6;
      }
    ],
    onReady: function (dateObj, dateStr, instance) {
      const $clear = $(
          '<div class="flatpickr-clear text-center p-2"><button class="btn btn-dark">Clear</button></div>'
        )
        .on("click", () => {
          instance.clear();
          instance.close();
        })
        .appendTo($(instance.calendarContainer));
    }
  });

  flatpickr(".dob", {
    disableMobile: "true",
    dateFormat: "m/d/Y",
    allowInput: true,
    maxDate: new Date().fp_incr(-6574), // set max date to 18 years ago or 6574 days ago
    onReady: function (dateObj, dateStr, instance) {
      const $clear = $(
          '<div class="flatpickr-clear text-center p-2"><button class="btn btn-dark">Clear</button></div>'
        )
        .on("click", () => {
          instance.clear();
          instance.close();
        })
        .appendTo($(instance.calendarContainer));
    }
  });

  // SSN field traversing
  $(".ssn-field").on("input", function () {
    if (
      $(this).val().length == $(this).attr("maxlength") &&
      $(this).attr("maxlength") != 4
    ) {
      $(this)
        .parent()
        .next()
        .next()
        .children(":first-child")
        .trigger('focus');
    } else if ($(this).val().length == 0 && $(this).attr("maxlength") != 3) {
      $(this)
        .parent()
        .prev()
        .prev()
        .children(":first-child")
        .trigger('focus');
    }
  });

  // bank routing lookup - validates routing number and populates bank name
  $("#bank_routing_number").on("blur", function() {
    let bankRoutingNumber = $(this).val();
    if (bankRoutingNumber.length >= 9) {
      $.get("/api/bank-lookup?routingNumber=" + bankRoutingNumber, function (data) {
        if (data.success == 1) {
          $("#bank_name").val(data.bank_name);
          $("#bank_name").prop("readonly", true);
        } else {
          $("#bank_name").val("");
          $("#bank_name").prop("readonly", false);
        }
      }).fail(function () {
        $("#bank_name").val("");
        $("#bank_name").prop("readonly", false);
      });
    }
  });

  // display modal error depending on military selection
  $("input[type=radio][name=is_military]").on("change", function() {
    if (this.value == "Yes") {
      $("#modalMilitaryError").modal("show");
      this.checked = false;
    }
  });

  // display modal error if they select "savings" account
  $("#bank_account_type").on("change", function() {
    if (this.value == "Savings") {
      $("#bankAccountTypeError").modal("show");
      this.value = '';
    }
  });

  // handle next/previous buttons for form
  $(document).on("click", ".nextBtn", function () {
    let currentStep = $(this).data("step");
    let isStepValid = validateStep(currentStep);
    if (isStepValid) {
      if (currentStep == 4) {
        $(this).prop('disabled', true);
        $("#loan_app").trigger('submit'); // submit form via jQuery
      } else {
        handleNextStep(currentStep);
      }
    }
  });

  $(document).on("click", ".prevBtn", function () {
    let currentStep = $(this).data("step");
    handlePrevStep(currentStep);
  });

  // Save Disclaimer
  $("#save_disclaimer").on("click", function (e) {
    e.preventDefault();
    HtmlUtility.html2Pdf(document.getElementById("consentText").innerHTML, "DisclosureStatement.pdf");
  });

  // Print Disclaimer
  $("#print_disclaimer").on("click", function (e) {
    e.preventDefault();
    HtmlUtility.printHtml(document.getElementById("consentText").innerHTML);
  });

  function buildValidator() {
    $("#loan_app").validate({
      errorElement: "div",
      errorClass: "is-invalid",
      focusInvalid: false,
      groups: LoanAppValidator.getGroups(),
      highlight: function (element, errorClass) {
        if ($(element).attr('type') !== "radio" && $(element).attr('type') !== "checkbox") {
          $(element).addClass(errorClass)
        }
      },
      invalidHandler: function (form, validator) {
        if (!validator.numberOfInvalids()) return;

        $("html, body").animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 30
        }, 1000);
      },
      errorPlacement: function (error, element) {
        error.addClass("invalid-feedback");
        if (element.is(":radio") || element.is(":checkbox")) {
          error.appendTo(element.parent().parent());
        } else if (
          element.attr("id").startsWith("ssn") ||
          element.attr("id").startsWith("confirm_ssn")
        ) {
          error.appendTo(
            element
            .parent()
            .parent()
            .parent()
          );
        } else {
          error.insertAfter(element);
        }
      }
    });

    $("#loan_app").valid();
  }

  function validateStep(step) {

    if (debug === true)
      return true;

    // grab the rules for the passed step
    let rules = LoanAppValidator.getRules(step);
    for (var key in rules) {
      // apply the rules
      $("#" + key).rules("add", rules[key]);
    };

    // returns a true or false
    return $("#loan_app").valid();
  }

  function handleNextStep(currentStep) {
    let nextStep = currentStep + 1;
    storeFormInSession();

    // clear any errors
    clearErrors();
    // initialize step
    initStep(nextStep);
    // set step
    setStep(nextStep);

    resetScrollPosition();
  }

  function handlePrevStep(currentStep) {
    let nextStep = currentStep - 1;
    storeFormInSession();

    // clear any errors
    clearErrors();
    // initialize step
    initStep(nextStep);
    // set step
    setStep(nextStep);

    resetScrollPosition();
  }

  function initStep(step) {
    // adjust the dom if needed
    if (step == 4) {
      let loanApp = getLoanApp();
      $(".customer-cell-phone").text(loanApp.cell_phone);
    }
  }

  function resetScrollPosition() {
    $("html,body").animate({
      scrollTop: $("#loan_app").offset().top
    });
  }

  function storeFormInSession() {
    let ignoreFields = "#_token, #hiddenAutoComplete";
    let loanApp = $("#loan_app input, #loan_app select")
      .not(ignoreFields)
      .serializeObject();

    let loanAppEncrypted = CryptoJS.AES.encrypt(
      JSON.stringify(loanApp),
      getCKey()
    );

    sessionStorage.removeItem("loanApp"); // clear our existing storage
    sessionStorage.setItem("loanApp", loanAppEncrypted); // update with new
  }

  function populateFormAndSetStep() {
    if ("step" in sessionStorage) {
      let step = sessionStorage.getItem("step");

      // if hidden input "goToStep" exists, then we need to overwrite our step ( from server side validation fail )
      if ($("#goToStep").length > 0) {
        step = $("#goToStep").val();
      }

      let loanApp = getLoanApp();
      let radioFields = ["is_military"];
      $.each(loanApp, function (field, value) {
        if (radioFields.includes(field)) {
          $('#loan_app [name="' + field + '"][value="' + value + '"]').prop(
            "checked",
            true
          );
        } else {
          $('#loan_app [name="' + field + '"]').val(value);
        }
      });

      initStep(step);
      setStep(step, true);
    }
  }

  function getLoanApp() {
    var bytes = CryptoJS.AES.decrypt(
      sessionStorage.getItem("loanApp").toString(),
      getCKey()
    );
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

  function setStep(nextStep, pageLoad = false) {
    let transitionToStep = true;
    // prevent page flicker when initially loading form
    if (pageLoad && nextStep == 1) {
      transitionToStep = false;
    }
    if (transitionToStep) {
      // hide steps
      $(".form-step-container").hide("slow");

      // determine next step to show
      if (nextStep == 2) {
        // show step 2
        $("#employment_fields").show("slow");
      } else if (nextStep == 3) {
        // show step 3
        $("#banking_fields").show("slow");
      } else if (nextStep == 4) {
        // show step 4
        $("#disclosure_fields").show("slow");
      } else {
        $("#get_started_fields").show("slow");
      }
    }

    // set the correct banner
    $("header.banner").removeClass(
      "form-step-1 form-step-2 form-step-3 form-step-4"
    );
    $("header.banner").addClass("form-step-" + nextStep);

    // highlight active step
    $(".form-step").removeClass("active");
    $("#formStep" + nextStep).addClass("active");

    // set step in session storage
    sessionStorage.setItem("step", nextStep);
  }

  function getCKey() {
    return "n0SN43LO9CSPj4WLVEWiGOzooZaguasd";
  }

  function clearErrors() {
    if ($("#goToStep").length > 0) {
      $("#serverErrors").remove();
    }
  }

  // Additional Validation
  // Restricts input for the given textbox to the given inputFilter.
  function setInputFilter(textbox, inputFilter) {
    [
      "input",
      "keydown",
      "keyup",
      "mousedown",
      "mouseup",
      "select",
      "contextmenu",
      "drop"
    ].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        }
      });
    });
  }

  let popUpInterVal;
  let idleInterVal;
  let popUpTimer;
  let duration = 1800;

  function checkIdle() {

    clearInterval(idleInterVal);

    var minutes = false; // change to false if you'd rather use seconds
    var interval = minutes ? 60000 : 1000;
    var IDLE_TIMEOUT = 600; // 3 minutes in this example
    var idleCounter = 0;

    document.onmousemove = document.onkeypress = function () {
      idleCounter = 0;
    };

    idleInterVal = setInterval(function () {
      if (++idleCounter >= IDLE_TIMEOUT) {
        $('#timeOut').modal({backdrop: 'static', keyboard: false});
        $('#timeOut').on('shown.bs.modal', function () {
          CountDown();
        });
      }
    }, interval);
  }

  function continueActivity(){
    refreshToken();
    // Clear Timer and Hide the pop up modal
    $('#timeOut').modal('hide');
    clearTimer();
  }

  function CountDown() {
    if (!isNaN(duration)) {

      let popUpTimer = duration, minutes, seconds;

      clearInterval(popUpInterVal);

      popUpInterVal = setInterval(function () {

        minutes = parseInt(popUpTimer / 60, 10);
        seconds = parseInt(popUpTimer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        $('#countdown_timer').html("<b>" + minutes + ":" + seconds + "</b>");

        if (--popUpTimer < 0) {
          popUpTimer = duration;
          clearInterval(popUpInterVal);
          window.location.href = '/';
        }

      },1000);
    }
  }

  function clearTimer()
  {
    clearInterval(popUpInterVal);
    // Set the popup timer
    popUpTimer = duration;
    // Reset the HTML timer
    $('#countdown_timer').html("<b>" + "30" + ":" + '00' + "</b>");
    // Reinit the Idle checker
    checkIdle();
  }

  let sessionPopUpInterVal;
  let sessionInterVal;
  let sessionPopUpTimer;

  function sessionExpiring(time) {

    let timer = time * 60000;
    clearInterval(sessionInterVal);

    sessionInterVal = setInterval(function(){
      //$('#sessionOut').modal('show');
      $('#sessionOut').modal({backdrop: 'static', keyboard: false});
      $('#sessionOut').on('shown.bs.modal', function () {
        sessionCountDown();
      });
      }, timer);
  }

  function sessionCountDown(duration = 1800) {
    if (!isNaN(duration)) {

      let sessionPopUpTimer = duration, minutes, seconds;

      clearInterval(sessionPopUpInterVal);

      sessionPopUpInterVal = setInterval(function () {

        minutes = parseInt(sessionPopUpTimer / 60, 10);
        seconds = parseInt(sessionPopUpTimer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        $('#session_timer').html("<b>" + minutes + ":" + seconds + "</b>");

        if (--sessionPopUpTimer < 0) {
          sessionPopUpTimer = duration;
          clearInterval(sessionPopUpInterVal);
          window.location.href = '/';
        }

      },1000);
    }
  }

  function sessionClearTimer()
  {
    clearInterval(sessionPopUpInterVal);
    // Set the popup timer
    sessionPopUpTimer = 1800;
    // Reset the HTML timer
    $('#session_timer').html("<b>" + "30" + ":" + '00' + "</b>");
  }

  function refreshToken(){
    $.get('refresh-csrf').done(function(data){
      // Make sure we set the new token
      $('meta[name=csrf-token]').attr('content', data);
      $('#_token').val(data);
      // Clear Timer and Hide the pop up modal
      $('#sessionOut').modal('hide');
      sessionClearTimer();
    });
  }
}