// Custom Validation Methods
// --

$.validator.addMethod(
  "minAge",
  function(value, element, min) {
    var today = new Date();
    var birthDate = new Date(value);
    var age = today.getFullYear() - birthDate.getFullYear();

    if (age > min + 1) {
      return true;
    }

    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= min;
  },
  "You are not old enough!"
);

$.validator.addMethod("maxAge", function(value, element, max) {
    var today = new Date();
    var birthDate = new Date(value);
    var age = today.getFullYear() - birthDate.getFullYear();

    if (age < max+1) {
        return true;
    }

    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age <= max;
}, "You are to old!");

$.validator.addMethod("checkFutureDay", function(value, element, checkFutureDay) {

    //Check to make sure the date is valid on front-end
    let parts = value.split('/');
    let year = new Date().getFullYear();
    let inputYear = parts[2].replace(/[^0-9]/g, '');

    if(inputYear < year)
        return false;

    var selectedDate = new Date(value);
    var now = new Date();

    if (selectedDate < now) {
        return false;
    }
    else {
        return true;
    }

}, "Date must be in the future.");

$.validator.addMethod("checkWeekend", function(value, element, checkFutureDay) {

    var payDay = new Date(value);

    var dayOfWeek = payDay.getDay();
    var isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday

    return !isWeekend;

}, "Payment dates that fall on Saturday or Sunday are not accepted. Please list the next business day as your Next Pay Date.");

$.validator.addMethod(
  "requiredValue",
  function(value, element, requiredValue) {
    return value == requiredValue;
  },
  "Required value is incorrect."
);

$.validator.addMethod(
  "lettersOnly",
  function(value, element) {
    return this.optional(element) || /^[a-z\s']+$/i.test(value);
  },
  "Letters only please"
);

$.validator.addMethod(
  "digitsWithSpecialChars",
  function(value, element) {
    if (/^[0-9\-() ]+$/i.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  "Please enter a valid Phone Number"
);

$.validator.addMethod(
  "promo",
  function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 ]+$/i.test(value);
  },
  "Letters or numbers only please"
);

$.validator.addMethod("checkEmail", function (value, element) {

    let regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);

}, "Please enter a valid email address");

$.validator.addMethod("checkBankRouting", function (value, element) {

    let i, n, t;

    // First, remove any non-numeric characters.

    t = "";
    for (i = 0; i < value.length; i++) {
        c = parseInt(value.charAt(i), 10);
        if (c >= 0 && c <= 9)
            t = t + c;
    }

    // Check the length, it should be nine digits.

    if (t.length != 9)
        return false;

    // Now run through each digit and calculate the total.

    n = 0;
    for (i = 0; i < t.length; i += 3) {
        n += parseInt(t.charAt(i),     10) * 3
            +  parseInt(t.charAt(i + 1), 10) * 7
            +  parseInt(t.charAt(i + 2), 10);
    }

    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.

    if (n != 0 && n % 10 == 0)
        return true;
    else
        return false;

}, "Please enter a valid routing number");
