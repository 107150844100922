export default {

  getRules(step) {

    switch(step) {
      case 1:
        return {
          'first_name': { required: true, lettersOnly: true },
          'last_name': { required: true, lettersOnly: true },
          'email': { required: true, email: true, checkEmail: true },
          'email_confirmation': { required: true, equalTo: "#email", messages: { equalTo: "Email Addresses must be the same." } },
          'cell_phone': { required: true, digitsWithSpecialChars: true },
          'home_phone': { required: true, digitsWithSpecialChars: true },
          'loan_request_amount': { required: true },
          'date_of_birth': { required: true, minAge: 18, maxAge: 100, messages: { minAge: "You must be 18 years or older to proceed.", maxAge: "You must be 100 years or younger to proceed." } },
          'is_military_no': { required: true, requiredValue: "No", messages: { requiredValue: "We do not offer loans to military or family of military." } },
          'address': { required: true },
          'city': { required: true, lettersOnly: true },
          'state': { required: true },
          'zip': { required: true,  minlength: 5, maxlength: 5, digits: true },
          'rent_or_own': { required: true },
          'time_at_current_address_months': { required: true },
          'drivers_license_state': { required: true },
          'drivers_license_number': { required: true },
          'ssn1': { required: true, digits: true, minlength: 3, maxlength: 3 },
          'ssn1_confirmation': { equalTo: "#ssn1", messages: { equalTo: "SSN does not match." } },
          'ssn2': { required: true, digits: true, minlength: 2, maxlength: 2 },
          'ssn2_confirmation': { equalTo: "#ssn2", messages: { equalTo: "SSN does not match." } },
          'ssn3': { required: true, digits: true, minlength: 4, maxlength: 4 },
          'ssn3_confirmation': { equalTo: "#ssn3", messages: { equalTo: "SSN does not match." } }
        }

      case 2:
        return {
          'income_source': { required: true },
          'employer_name': { required: true },
          'work_phone': { required: true, digitsWithSpecialChars: true },
          'time_at_job_months': { required: true },
          'pay_frequency': { required: true },
          'is_direct_deposit': { required: true },
          'first_pay_day': { required: true, checkFutureDay: true, checkWeekend: true },
          'monthly_income': { required: true, number: true }
        }

      case 3:
        return {
          'bank_routing_number': { required: true, digits: true, minlength: 9, maxlength: 9, checkBankRouting: true },
          'bank_name': { required: true },
          'bank_account_type': { required: true },
          'bank_account_number': { required: true, digitsWithSpecialChars: true, minlength: 4, maxlength: 18, messages: { digitsWithSpecialChars: "Please enter valid Bank Account Number." } },
          'bank_account_number_confirmation': { equalTo: "#bank_account_number", messages: { equalTo: "Bank Account Number does not match.." } },
          'time_bank_account_open_months': { required: true }
        }

      case 4:
        return {
          'disclosure_federal_e_sign': { required: true,messages: {required: "You must agree to the electronic disclosure." } },
          'disclosure_marketing': { required: true, messages: { required: "You must agree to the electronic consent." } },
          'disclosure_third_party': { required: true, messages: { required: "You must agree to the disclosure." } },
          'disclosure_borrower_statement': { required: true, messages: { required: "You must agree to the disclosure." } },
          'disclosure_accurate': { required: true, messages: { required: "You must agree to the disclosure." } },
          'promocode': { required: false, promo: true}
        }

      default:
        return {};
    }

  },

  getGroups() {
    return {
      ssn: "ssn1 ssn2 ssn3",
      confirm_ssn: "ssn1_confirmation ssn2_confirmation ssn3_confirmation"
    }
  }

}